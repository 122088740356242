import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import ReactTooltip from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { ListChildComponentProps } from 'react-window';
import { ReactComponent as BounceIcon } from '../../assets/bounce.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import { ReactComponent as SortDownIcon } from '../../assets/sort-down.svg';
import { ReactComponent as SortUpIcon } from '../../assets/sort-up.svg';
import Checkbox from '../../shared/components/checkbox/Checkbox';
import ConfirmationModal from '../../shared/components/modal/ConfirmationModal';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import { usePrevious } from '../../shared/helpers';
import { useNotification } from '../../shared/hooks/useNotification';
import { usePendingDocuments } from '../../shared/hooks/usePendingDocuments';
import {
  FilterOptionType,
  PendingDocsSorting,
  PendingDocsSortingProp,
} from '../../shared/models';
import documentsSlice, {
  patchDocument,
  postWorkflowRuns,
} from '../../store/documentsSlice';
import { useDispatch, useSelector } from '../../store/store';
import m from '../../styles/style/_shared.module.scss';
import Filters from '../core/filters/Filters';
import s from './pending-documents.module.scss';
import PendingDocumentRow from './PendingDocumentRow';

interface Props {}

const PendingDocuments: React.FC<Props> = (props) => {
  const user = useSelector((state) => state.auth.user);

  const [activeFilters, setActiveFilters] = useState<FilterOptionType[]>([]);
  const [sorting, setSorting] = useState<PendingDocsSorting>({
    prop: 'uploadTime',
    descending: true,
  });

  const dispatch = useDispatch();
  const checkedDocIds = useSelector((state) => state.documents.checkedDocIds);
  const { setContent, showNotification, setType } = useNotification();
  const { documentList, mutationList, provisioningList } = usePendingDocuments(
    false,
    activeFilters
  );
  const prevDocList: any = usePrevious(documentList);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);

  const listRef = useRef();

  const [sortedDocumentList, setSortedDocumentList] = useState([]);
  useEffect(() => {
    if (documentList && mutationList && provisioningList) {
      let list = [...documentList, ...provisioningList, ...mutationList]
        .sort((a, b) => {
          let sortingValue;
          switch (sorting.prop) {
            case 'breadcrumbs': {
              const brB = b.breadcrumbs
                ? b.breadcrumbs[b.breadcrumbs.length - 1].name
                : '?';
              const brA = a.breadcrumbs
                ? a.breadcrumbs[a.breadcrumbs.length - 1].name
                : '?';
              sortingValue = brB.localeCompare(brA);
              break;
            }
            case 'inbox': {
              const nameB = a.inbox ? a.inbox.name : a.routerId;
              const nameA = b.inbox ? b.inbox.name : b.routerId;
              sortingValue = nameB.localeCompare(nameA);
              break;
            }
            case 'uploadTime': {
              sortingValue = b.uploadTime.getTime() - a.uploadTime.getTime();
              break;
            }
            case 'tenant': {
              sortingValue = b.tenantId.localeCompare(a.tenantId);
              break;
            }
            case 'name': {
              if (b.name && a.name) sortingValue = b.name.localeCompare(a.name);
              else sortingValue = b.id.localeCompare(a.id);

              break;
            }
            default:
              sortingValue = 0;
              break;
          }
          if (sorting.descending) sortingValue = sortingValue * -1;

          return sortingValue;
        })
        .filter((e) => !e.isHidden);
      setSortedDocumentList(list);
    } else {
      return setSortedDocumentList([]);
    }
  }, [provisioningList, mutationList, documentList, sorting]);

  const checkedDocuments = useMemo(
    () => sortedDocumentList?.filter((e) => checkedDocIds.includes(e.id)),
    [sortedDocumentList, checkedDocIds]
  );

  const handleSetSorting = (prop: PendingDocsSortingProp) => {
    if (sorting.prop === prop) {
      setSorting({ ...sorting, descending: !sorting.descending });
    } else {
      setSorting({ prop, descending: false });
    }
    if (listRef.current) {
      const list = listRef.current as HTMLDivElement;
      list.scrollTo({ top: 0 });
    }
  };
  const handleCheckAll = (isChecked: boolean) => {
    dispatch(
      documentsSlice.actions.setCheckedDocIds(
        isChecked ? sortedDocumentList.map((e) => e.id) : []
      )
    );
  };

  const handleBounceChecked = async () => {
    let lists = cloneDeep([documentList, mutationList, provisioningList]);
    const promiseList = []; // Not sure where you are using this, since it's not used in your provided code
    let existingDoc;
    for (const k in checkedDocuments) {
      if (checkedDocuments[k].action) continue;
      for (let list of lists) {
        let doc = list.find((e) => e.id === checkedDocuments[k].id);
        if (doc) {
          let newDoc = { ...doc, isHidden: true };
          list[list.indexOf(doc)] = newDoc;
          existingDoc = newDoc;
          break;
        }
      }

      promiseList.push(
        dispatch(
          patchDocument(
            { action: { type: 'bounce' } },
            existingDoc.id,
            existingDoc.tenantId,
            existingDoc.inbox?.id ?? null,
            existingDoc.routerId ?? null,
            existingDoc.isMutation ? existingDoc.documentId : null
          )
        )
      );
    }
    dispatch(documentsSlice.actions.setDocumentList(lists[0]));
    dispatch(documentsSlice.actions.setMutationList(lists[1]));
    dispatch(documentsSlice.actions.setProvisioningList(lists[2]));
    await Promise.all(promiseList)
      .then((res) => {
        if (res[0].status === 204) {
          handleCheckAll(false);
          setContent('Document bounced successfully');
          showNotification();
        }
      })
      .catch(() => {
        let lists = cloneDeep([documentList, mutationList, provisioningList]);
        for (const k in checkedDocuments) {
          for (let list of lists) {
            let doc = list.find((e) => e.id === checkedDocuments[k].id);
            if (doc) {
              let newDoc = { ...doc, isHidden: false, isChecked: false };
              list[list.indexOf(doc)] = newDoc;
              existingDoc = newDoc;
              break;
            }
          }
        }
        dispatch(documentsSlice.actions.setDocumentList(lists[0]));
        dispatch(documentsSlice.actions.setMutationList(lists[1]));
        dispatch(documentsSlice.actions.setProvisioningList(lists[2]));
        setType('error');
        setContent('Document bounce failed');
        showNotification();
      });
  };

  const handleDeleteChecked = async () => {
    setIsDeleteModalActive(false);
    let lists = cloneDeep([documentList, mutationList, provisioningList]);
    let existingDoc;
    const promiseList = [];
    for (const k in checkedDocuments) {
      for (let list of lists) {
        let doc = list.find((e) => e.id === checkedDocuments[k].id);
        if (doc) {
          let newDoc = { ...doc, isHidden: true };
          list[list.indexOf(doc)] = newDoc;
          existingDoc = newDoc;
          break;
        }
      }

      promiseList.push(
        dispatch(
          postWorkflowRuns(
            existingDoc.id,
            existingDoc.tenantId,
            existingDoc.inbox?.id ?? null,
            existingDoc.routerId ?? null,
            existingDoc.isMutation ? existingDoc.documentId : null,
            true
          )
        )
      );
    }
    await Promise.all(promiseList)
      .then((res) => {
        if (res[0].status === 204) {
          handleCheckAll(false);
          setContent('Document Delete triggered.');
          showNotification();
        }
      })
      .catch(() => {
        setType('error');
        setContent('Document delete trigger failed');
        showNotification();
      });
  };

  const handleReprocessChecked = async () => {
    let lists = cloneDeep([documentList, mutationList, provisioningList]);
    let existingDoc;
    const promiseList = [];
    for (const k in checkedDocuments) {
      for (let list of lists) {
        let doc = list.find((e) => e.id === checkedDocuments[k].id);
        if (doc) {
          let newDoc = { ...doc, isHidden: true };
          list[list.indexOf(doc)] = newDoc;
          existingDoc = newDoc;
          break;
        }
      }
      promiseList.push(
        dispatch(
          postWorkflowRuns(
            existingDoc.id,
            existingDoc.tenantId,
            existingDoc.inbox?.id ?? null,
            existingDoc.routerId ?? null,
            existingDoc.documentId !== existingDoc.id ? existingDoc.documentId : null
          )
        )
      );
    }
    dispatch(documentsSlice.actions.setDocumentList(lists[0]));
    dispatch(documentsSlice.actions.setMutationList(lists[1]));
    dispatch(documentsSlice.actions.setProvisioningList(lists[2]));
    await Promise.all(promiseList)
      .then((res) => {
        if (res[0].status === 204) {
          handleCheckAll(false);
          setContent('Document reprocessing started.');
          showNotification();
        }
      })
      .catch(() => {
        setType('error');
        setContent('Document reprocess trigger failed');
        showNotification();
      });
  };

  const selectionState = useMemo(() => {
    if (checkedDocuments.length === 0) {
      return 'none';
    } else if (sortedDocumentList.length === checkedDocuments.length) {
      return 'all';
    } else {
      return 'some';
    }
  }, [sortedDocumentList, checkedDocuments]);

  useEffect(() => {
    if (documentList?.length !== prevDocList?.length) {
      ReactTooltip.rebuild();
    }
  }, [documentList, prevDocList]);

  const ref = useRef();

  const getWorkflowContent = (docKey: string) => {
    const document = sortedDocumentList.find((doc) => {
      return doc.id + doc.tenantId + doc.documentId === docKey;
    });
    let workflowVersion;
    if (document?.breadcrumbs) {
      let version;
      document.breadcrumbs.forEach((bc) => {
        const v = bc.version?.split('@')[1];
        if (v) version = v;
      });
      workflowVersion = version;
    }
    return (
      <div className={s.status_tooltip}>
        {workflowVersion != null && (
          <div className={s.status_tooltip_header}>
            Workflow : {workflowVersion} ({document.breadcrumbs.length} steps)
          </div>
        )}
        {document?.breadcrumbs?.slice(-15).map((bc, i) => {
          const time =
            bc.timestamp.toLocaleDateString('be-NL') +
            ' ' +
            bc.timestamp.toLocaleTimeString('be-NL');
          const runIds = [...new Set(document.breadcrumbs.map((e) => e.correlationId))];
          if (runIds.length >= 3) {
            if (bc.correlationId !== runIds[runIds.length - 1]) {
              return null;
            }
          }

          return (
            <div key={bc.name + bc.timestamp.getTime()} className={s.status_tooltip_row}>
              <span>{bc.name.replace(/([A-Z])/g, ' $1').trim()}</span>
              <span>{time}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={m.container}>
      {isDeleteModalActive && (
        <ConfirmationModal
          isOpen={isDeleteModalActive}
          setIsOpen={setIsDeleteModalActive}
          title={`Delete document(s)`}
          handleConfirm={handleDeleteChecked}
          handleCancel={() => {
            let existingDocs = cloneDeep(documentList);
            existingDocs = existingDocs.map((doc) => ({ ...doc, pendingDelete: false }));
            dispatch(documentsSlice.actions.setDocumentList(existingDocs));
          }}
          buttonText={'DELETE'}
          confirmType={'delete'}
          body={
            'Warning: This action is irreversible. Deleting documents is permanent and cannot be undone. Please ensure you understand the consequences before proceeding.'
          }
        />
      )}
      <div className={m.page_header}>
        <h1 className={m.page_title}>Pending Documents</h1>
      </div>
      <Filters
        setActiveFilters={setActiveFilters}
        activeFilters={activeFilters}
        baseFiltersList={[
          {
            type: 'option',
            id: 'tenantId',
            name: 'Tenant Id',
            options: [],
          },
          {
            type: 'option',
            id: 'inboxId',
            name: 'Inbox Id',
            options: [],
          },
        ]}
      />

      <div className={s.table}>
        <div className={s.header}>
          {user.role === 'superUser' && (
            <button className={clsx(s.first)}>
              <Checkbox
                checked={selectionState === 'all'}
                indeterminate={selectionState === 'some'}
                onClick={() =>
                  handleCheckAll(
                    !(checkedDocuments?.length === sortedDocumentList?.length)
                  )
                }
              />
            </button>
          )}
          <button
            className={clsx(s.header_name, s.second)}
            onClick={() => handleSetSorting('name')}
          >
            <span>Name</span>
            {sorting.prop === 'name' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(s.header_name, s.third)}
            onClick={() => handleSetSorting('tenant')}
          >
            <span> Tenant ID</span>
            {sorting.prop === 'tenant' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(s.header_name, s.fourth)}
            onClick={() => handleSetSorting('inbox')}
          >
            <span> Inbox / Router</span>
            {sorting.prop === 'inbox' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(s.header_name, s.fifth)}
            onClick={() => handleSetSorting('uploadTime')}
          >
            <span> Upload Date</span>
            {sorting.prop === 'uploadTime' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(s.header_name, s.sixth)}
            onClick={() => handleSetSorting('breadcrumbs')}
          >
            <span> Status / Attempts</span>
            {sorting.prop === 'breadcrumbs' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          {user.role === 'superUser' && <div className={s.seventh}>Actions</div>}
        </div>

        <Tooltip id={`cloud`} text={'Go to document in GCS'} />
        <Tooltip id={`copy`} text={'Copy document ID'} />
        <Tooltip id={`bounce`} text={'Bounce document.'} />
        <Tooltip id={`reprocess`} text={'Reprocess document.'} />
        <Tooltip id={`firestore`} text={'Go to document in Firestore'} />
        <ReactTooltip
          getContent={getWorkflowContent}
          id="custom-tooltip-radius"
          className={s.status_tooltip_wrapper}
          backgroundColor={'#1b1e21'}
          arrowColor={'#1b1e21'}
          place="left"
          effect="solid"
        />
        <div ref={ref} className={s.body}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                onScroll={() => ReactTooltip.rebuild()}
                height={height}
                itemCount={sortedDocumentList.length}
                itemSize={53}
                itemData={sortedDocumentList as any}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
      {checkedDocuments?.length > 0 && user.role === 'superUser' && (
        <div className={s.toolbar}>
          <div className={s.toolbar_counter}>{checkedDocuments.length}</div>
          <p className={s.toolbar_text}>
            {checkedDocuments.length > 1 ? 'Documents ' : 'Document '}selected
          </p>
          <div className={s.toolbar_buttons}>
            <button
              onClick={() => {
                setIsDeleteModalActive(true);
                let existingDocs = cloneDeep(documentList);
                existingDocs = existingDocs.map((doc) => {
                  return {
                    ...doc,
                    pendingDelete: !!checkedDocuments.find((e) => e.id === doc.id),
                  };
                });
                dispatch(documentsSlice.actions.setDocumentList(existingDocs));
              }}
              className={s.document_item_action}
            >
              <DeleteIcon style={{ transform: 'scale(0.7)' }} />
            </button>
            <button onClick={handleReprocessChecked} className={s.document_item_action}>
              <ReloadIcon />
            </button>
            <button onClick={handleBounceChecked} className={s.document_item_action}>
              <BounceIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingDocuments;

const Row: React.FC<ListChildComponentProps> = ({ index, style, data }) => {
  const doc = data[index];
  const user = useSelector((state) => state.auth.user);

  return (
    <div
      key={doc.id + doc.tenantId + doc.documentId}
      style={{
        ...style,
      }}
    >
      <PendingDocumentRow
        inbox={doc.inbox}
        tenantId={doc.tenantId}
        document={doc}
        user={user}
      />
    </div>
  );
};
