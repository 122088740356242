import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { Unsubscribe } from 'firebase/auth';
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore';
import { snakeCase } from 'lodash';
import { db } from '../firebase/firebase-setup';
import { getUserToken, operatorMap } from '../shared/helpers';
import {
  Structure,
  Document,
  Inbox,
  Tenant,
  Router,
  FilterOptionType,
} from '../shared/models';
import { modelsSlice } from './modelsSlice';
import { AppThunk } from './store';

interface DocumentsState {
  structureLoaded?: boolean;
  structure?: Structure;
  documentList?: Document[];
  provisioningList?: Document[];
  mutationList?: Document[];
  tenantIds?: string[];
  tenants?: Tenant[];
  checkedDocIds: string[];
}

const initialState: DocumentsState = {
  documentList: [],
  mutationList: [],
  provisioningList: [],
  checkedDocIds: [],
};

export const documentsSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setStructureLoaded: (state, action: PayloadAction<boolean>) => {
      state.structureLoaded = action.payload;
    },
    setStructure: (state, action: PayloadAction<Structure>) => {
      state.structure = action.payload;
    },
    setDocumentList: (state, action: PayloadAction<Document[]>) => {
      state.documentList = action.payload;
    },
    setProvisioningList: (state, action: PayloadAction<Document[]>) => {
      state.provisioningList = action.payload;
    },
    setMutationList: (state, action: PayloadAction<Document[]>) => {
      state.mutationList = action.payload;
    },
    setTenantIds: (state, action: PayloadAction<string[]>) => {
      state.tenantIds = action.payload;
    },
    setCheckedDocIds: (state, action: PayloadAction<string[]>) => {
      state.checkedDocIds = action.payload;
    },
  },
});

export const getPendingDocCountSelector = createSelector(
  [
    (state) => state.documents.documentList,
    (state) => state.documents.mutationList,
    (state) => state.documents.provisioningList,
  ],
  (documentList, mutationList, provisioningList) => {
    if (documentList) {
      return [...documentList, ...mutationList, ...provisioningList].length;
    }
    return null;
  }
);
export const postWorkflowRuns =
  (
    documentId: string,
    tenantId: string,
    inboxId?: string,
    routerId?: string,
    parentId?: string,
    isDelete?: boolean
  ) =>
  async (dispatch, getState) => {
    const b = await getUserToken();

    let url;
    if (inboxId) {
      if (parentId) {
        url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${tenantId}/inboxes/${inboxId}/documents/${parentId}/mutations/${documentId}/workflow_runs`;
      } else {
        url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${tenantId}/inboxes/${inboxId}/documents/${documentId}/workflow_runs`;
      }
    } else if (routerId) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${tenantId}/routers/${routerId}/documents/${documentId}/workflow_runs`;
    }
    var params = isDelete
      ? {
          workflow_name: 'onActionEnd',
        }
      : null;
    return axios.post(url, null, {
      params,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + b,
      },
    });
  };

export const patchDocument =
  (
    payload: any,
    documentId: string,
    tenantId: string,
    inboxId?: string,
    routerId?: string,
    parentId?: string
  ) =>
  async (dispatch, getState) => {
    const b = await getUserToken();

    let url;
    if (inboxId) {
      if (parentId) {
        url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${tenantId}/inboxes/${inboxId}/documents/${parentId}/mutations/${documentId}`;
      } else {
        url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${tenantId}/inboxes/${inboxId}/documents/${documentId}`;
      }
    } else if (routerId) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${tenantId}/routers/${routerId}/documents/${documentId}`;
    }
    return axios.patch(url, payload, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + b,
      },
    });
  };

export const getInfo = (): AppThunk => async (dispatch, getState) => {
  const docPath = `tenants`;
  let colRef = collection(db, docPath);
  let newStructure: Structure = {};
  dispatch(documentsSlice.actions.setStructureLoaded(false));
  dispatch(documentsSlice.actions.setStructure(null));

  await getDocs(colRef).then(async (res) => {
    const list = res.docs;
    newStructure.tenants = list.map((tenant) => {
      return { id: tenant.id, inboxes: [] } as Tenant;
    });

    for await (const tenant of newStructure.tenants) {
      let tenantRef = doc(db, `tenants/${tenant.id}`);
      await getDoc(tenantRef).then((tenantRes) => {
        console.log(tenantRes.data());
        tenant.name = tenantRes.data().settings.name;
      });
      let inboxRef = collection(db, `tenants/${tenant.id}/inboxes`);
      await getDocs(inboxRef).then((inboxRes) => {
        const inboxes = [];
        inboxRes.forEach((inbox) => {
          inboxes.push({
            id: inbox.id,
            name: inbox.data().settings.name,
            workflowVersion: inbox.data().config.workflow_version,
          } as Inbox);
        });
        tenant.inboxes = inboxes;
      });
      let routerRef = collection(db, `tenants/${tenant.id}/routers`);
      await getDocs(routerRef).then((routerRes) => {
        const routers = [];
        routerRes.forEach((router) => {
          routers.push({
            id: router.id,
          } as Router);
        });
        tenant.routers = routers;
      });
    }
    dispatch(documentsSlice.actions.setTenantIds([...new Set(list.map((e) => e.id))]));

    dispatch(documentsSlice.actions.setStructure(newStructure));
    dispatch(modelsSlice.actions.setActiveTenant(newStructure?.tenants[0]));
    dispatch(documentsSlice.actions.setStructureLoaded(true));
  });
};

const handleDocs = async (
  existing: Document[],
  data: QuerySnapshot,
  getState,
  isMutation = false
) => {
  const structure = getState().documents.structure;

  let documents = data.docs.map((doc) => {
    const path = doc.ref.path;
    const tenantId = path.match(/tenants\/(?<v>.*?)(?=\/)/).groups.v;

    const data = doc.data();
    const document = camelcaseKeys(data, { deep: true }) as Document;

    try {
      document.id = doc.id;
      document.isMutation = isMutation;
      document.uploadTime = data['upload_time']?.toDate();
      document.lastUpdatedDate = data['last_updated_date']?.toDate();
      document.lastOpenedDate = data['last_opened_date']?.toDate();
      document.breadcrumbs = data['breadcrumbs']?.map((bc) => {
        return { ...camelcaseKeys(bc), timestamp: bc['timestamp'].toDate() };
      });
    } catch (e) {
      return null;
    }
    if (data['inbox_id']) {
      const tenant = structure.tenants.find((t) => t.id === tenantId);
      const inbox = tenant.inboxes.find((i) => i.id === data['inbox_id']);

      if (!inbox) return null;
      document.inbox = {
        id: data['inbox_id'],
        name: inbox.name,
      };
      document.routerId = null;
    } else if (data['router_id']) {
      document.routerId = data['router_id'];
      document.inbox = null;
    }
    document.tenantId = tenantId;
    const existingDoc = existing.find((ex) => ex.id === document.id);
    document.isChecked = existingDoc?.isChecked ?? false;
    if (existingDoc && existingDoc.reprocessTriggered) {
      if (existingDoc.breadcrumbs.length === document.breadcrumbs.length) {
        return existingDoc;
      }
    }
    return document;
  });
  console.log(documents);
  documents = documents.filter((e) => e !== null);
  return documents;
};
export const parseFilters = (activeFilters: FilterOptionType[]) => {
  const filters = [];
  activeFilters.forEach((fil) => {
    if (fil.value.value2 && fil.value.operator === 'between') {
      filters.push(where(snakeCase(fil.id), '>', new Date(fil.value.value1)));
      filters.push(where(snakeCase(fil.id), '<', new Date(fil.value.value2)));
    } else if (fil.type === 'date') {
      filters.push(
        where(
          snakeCase(fil.id),
          operatorMap[fil.value.operator],
          new Date(fil.value.value1)
        )
      );
    } else {
      filters.push(
        where(snakeCase(fil.id), operatorMap[fil.value.operator], fil.value.value1)
      );
    }
  });
  return filters;
};

let pendingDocListeners: Unsubscribe[] = [];
let altPendingDocListeners: Unsubscribe[] = [];
export const listenForPendingDocs =
  (activeFilters: FilterOptionType[]): AppThunk =>
  async (dispatch, getState) => {
    const structure = getState().documents.structure;
    if (pendingDocListeners) {
      pendingDocListeners.forEach((listener) => {
        listener();
      });
      pendingDocListeners = [];
    }
    if (!structure) return;

    let filters = [
      ...parseFilters(activeFilters),
      where('latest_workflow_run.status', 'in', ['FAILED', 'DEAD']),
      orderBy('upload_time', 'asc'),
      limit(100),
    ];
    let pendingDocsQuery = query(collectionGroup(db, 'documents'), ...filters);
    const snapshot = onSnapshot(
      pendingDocsQuery,
      async (data) => {
        console.log(data);
        const existing = getState().documents.documentList;
        var preMapped = data.docs.map((e) => ({ id: e.id, ...e.data() }));
        const documents = await handleDocs(existing, data, getState);
        preMapped.forEach((doc) => {
          if (!documents.find((d) => d.documentId === doc.id)) {
            console.log(doc);
          }
        });
        dispatch(documentsSlice.actions.setDocumentList(documents));
        pendingDocListeners.push(snapshot);
      },
      (err) => {
        console.log(err);
      }
    );

    filters = [
      ...parseFilters(activeFilters),
      where('latest_workflow_run.status', '==', 'PROVISIONING'),
      where(
        'upload_time',
        '<=',
        new Date(Date.now() - 2 * 60 * 60 * 1000).toISOString().split('T')[0]
      ),
      orderBy('upload_time', 'asc'),
      limit(100),
    ];
    pendingDocsQuery = query(collectionGroup(db, 'documents'), ...filters);
    const altSnapshot = onSnapshot(
      pendingDocsQuery,
      async (data) => {
        const existing = getState().documents.provisioningList;
        const documents = await handleDocs(existing, data, getState);
        dispatch(documentsSlice.actions.setProvisioningList(documents));
        altPendingDocListeners.push(altSnapshot);
      },
      (err) => {
        console.log(err);
      }
    );
  };

let pendingMutationDocListeners: Unsubscribe[] = [];
export const listenForPendingMutationDocs =
  (activeFilters: FilterOptionType[]): AppThunk =>
  async (dispatch, getState) => {
    const structure = getState().documents.structure;
    if (pendingMutationDocListeners) {
      pendingMutationDocListeners.forEach((listener) => {
        listener();
      });
      pendingMutationDocListeners = [];
    }
    if (!structure) return;
    console.log(activeFilters);
    const filters = [
      ...parseFilters(activeFilters),
      where('latest_workflow_run.status', 'in', ['FAILED', 'DEAD']),
      orderBy('upload_time', 'asc'),
      limit(100),
    ];

    let pendingDocsQuery = query(collectionGroup(db, 'mutations'), ...filters);
    const altSnapshot = onSnapshot(
      pendingDocsQuery,
      async (data) => {
        const existing = getState().documents.mutationList;
        const documents = await handleDocs(existing, data, getState, true);
        dispatch(documentsSlice.actions.setMutationList(documents));
        pendingMutationDocListeners.push(altSnapshot);
      },
      (err) => {
        console.log(err);
      }
    );
  };

export const reprocessInbox = async (
  tenantId: string,
  inboxId: string,
  clear_keys: string[],
  workflow?: string
) => {
  const b = await getUserToken();
  if (!b) return;
  const data = { clear_keys };
  const params = {};
  if (workflow) {
    params['workflow_name'] = workflow;
  }
  return axios.post(
    `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${tenantId}/inboxes/${inboxId}/workflow_runs`,
    data,
    {
      params,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + b,
      },
    }
  );
};
export default documentsSlice;
